// Components
import BottomModal from "@/gc/modals/BottomModal";
import FontSizeModal from "@/src/components/splash-screen/sub-components/FontSizeModal";
import NoInventoryScreen from "@/components/splash-screen/sub-components/NoInventoryScreen";
import PageTitle from "@/gc/page-title/PageTitle";
import PrimaryButtonGroup from "@/gc/button/PrimaryButtonGroup";
import SecondaryButton from "@/src/components/splash-screen/sub-components/SecondaryButton";
import TopBanner from "@/gc/banner/TopBanner";

// Context
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Hooks
import { useRouter } from "next/router";

// Icons
import {
  faGarage,
  faInfo,
  faMap,
  faUsdCircle,
} from "@fortawesome/pro-solid-svg-icons";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Services
import * as localStorageService from "@/services/localStorageService";

// Themes
import theme from "@/theme";

// Types
import IIcon from "@/interfaces/global-components/button/IICon";
import IOption from "@/interfaces/global-components/button/IOption";
import ISecondaryButtonProps from "@/interfaces/splash-screen/sub-components/ISecondaryButtonProps";
import ISplashScreenProps from "@/interfaces/splash-screen/ISplashScreenProps";

const SplashScreenBody = ({
  content,
  displayNoInventoryScreen,
  handleForward,
  hasAvailableInventory,
  address,
  setDisplayNoInventoryScreen,
}: ISplashScreenProps) => {
  const [mainButtons, setMainButtons] = useState<Array<IOption>>([]);
  const [secondaryButtons, setSecondaryButtons] = useState<ISecondaryButtonProps[]>([]);
  const iconColor = theme.brandColors.primaryGreen;
  const iconSize = "3x";
  const garageIcon: IIcon = {
    color: iconColor,
    fontSize: "3.5rem",
    iconDefinition: faGarage,
    size: iconSize,
  };

  const {
    // fontSizeButtonLabel,
    fontSizeTitle,
  } = content.accesibilityModal?.fields;

  const [showFontSizeModal, setShowFontSizeModal] = useState(false);

  // Leave change font size in as there is a possibilty that it could make a glourious return
  // const openFontSizeModal = () => {
  //   setShowFontSizeModal(true);
  // };

  const {
    backgroundColor,
    fontColor,
    label,
  } = content.topBanner.fields;

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const createMainButtons = (): void => {
    const buttons: Array<IOption> = [
      hasAvailableInventory
        ? {
          description: content?.rentUnitOptionDescription,
          icon: garageIcon,
          label: content?.rentUnitOptionLabel,
          link: `/rent-unit?ozStoreId=${deviceInformation?.ozStoreId}`,
          testId: "rent-unit",
        }
        : {
          icon: garageIcon,
          label: content?.browseUnitsNoAvailabilityOptionLabel,
          onClick: () => setDisplayNoInventoryScreen(true),
          testId: "browse-locations-button",
        },

      {
        label: content?.paymentOptionLabel,
        description: content?.paymentOptionDescription,
        icon: {
          color: iconColor,
          fontSize: "4rem",
          iconDefinition: faUsdCircle,
          size: iconSize,
        },

        link: "/payment/step-one",
        testId: "make-payment",
      },
    ];

    setMainButtons(buttons);
  }

  const createSecondaryButtons = (storeHasMap: boolean) => {
    const buttons: ISecondaryButtonProps[] = [
      {
        dataLayerEventName: "find-unit-page-event",
        label: content?.mapOptionLabel,
        icon: {
          color: iconColor,
          iconDefinition: faMap,
        },
        id: "find-unit-page",
        onClick: navigateToViewFacilityMapPage,
        testId: "view-facility-map",
      },
      {
        dataLayerEventName: "hours-and-information-event",
        label: content?.hoursAndInformationOptionLabel,
        icon: {
          color: iconColor,
          iconDefinition: faInfo,
        },
        id: "hours-and-information",
        onClick: handleForward,
        testId: "hours-and-information",
      },
    ];

    if (!storeHasMap) {
      buttons.shift();
    }

    setSecondaryButtons(buttons);
  }

  const router = useRouter();

  const navigateToViewFacilityMapPage = async (): Promise<void> => {
    await router.push(`/facility-map/${deviceInformation?.ozStoreId}`);
  };

  useEffect(() => {
    const storeHasMap = localStorageService.storeHasMap();
    createMainButtons();
    createSecondaryButtons(storeHasMap);
  }, []);

  useEffect(() => {
    if (deviceInformation && deviceInformation.ozStoreId) {
      const storeHasMap = localStorageService.storeHasMap();
      createMainButtons();
      createSecondaryButtons(storeHasMap);
    }
  }, [deviceInformation, hasAvailableInventory]);

  return (
    <>
      {
        !hasAvailableInventory && (
          <TopBanner
            backGroundColor={backgroundColor}
            fontColor={fontColor}
            label={label}
            testId="no-available-units-banner"
          />
        )
      }
      {
        displayNoInventoryScreen
          ? (
            <NoInventoryScreen
              address={address}
              content={content}
            />
          )
          : (
            <>
              <PageTitle
                containerPadding={!hasAvailableInventory
                  ? "5.7% 0% 7% 0%"
                  : null}
                subTitle={content?.subHeader}
                title={content?.header}
              />
              {mainButtons.length > 0 && (
                <PrimaryButtonGroup
                  options={mainButtons}
                />
              )}
              {
                secondaryButtons.map((button) => {
                  return (
                    <div
                      className="option"
                      key={button.label}
                    >
                      <SecondaryButton
                        dataLayerEventName={button.dataLayerEventName}
                        href={button.href}
                        icon={button.icon}
                        id={button.id}
                        label={button.label}
                        testId={button.testId}
                        onClick={button.onClick}
                      />
                      <hr />
                    </div>
                  );
                })
              }
              {showFontSizeModal && (
                <BottomModal
                  showBackButton
                  backButtonOnClick={() => setShowFontSizeModal(false)}
                  height="36.1%"
                  setShowModal={setShowFontSizeModal}
                  testId="font-size-modal"
                  title={fontSizeTitle}
                >
                  <FontSizeModal content={content} />
                </BottomModal>
              )}
            </>
          )
      }
      <style jsx>
        {`
        hr {
          border: none;
          border-top: 3px solid ${theme.borderColors.lightGray};
          margin: 1.5rem 0;
          width: 77%;
        }

        .option {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 100%;
        }    
        
      `}
      </style>
    </>
  );
};

export default SplashScreenBody;
